import Head from 'next/head';
import React, { FC } from 'react';

import Footer from '../footer';
import Navbar from '../navbar';

const PageContainer: FC = ({ children }) => (
  <div>
    <Head>
      <meta
        name="keywords"
        content="kerja,kerjago,kerjago,cari kerja,part time, go kerja"
      />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    <div className="flex flex-col min-h-screen">
      <Navbar />
      {children}
      <Footer />
    </div>
  </div>
);

export default PageContainer;
