import { createContext } from 'react';

import { ToastController } from '@/lib/toast';

export const GlobalToastController = new ToastController(4000);

/* eslint-disable max-len */
export const GlobalToastContext = createContext<typeof GlobalToastController>(
  GlobalToastController,
);
