import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { HiMenu, HiX } from 'react-icons/hi';

import { useWindowWidth } from '@react-hook/window-size/throttled';

import BlurImage from '@/components/blurImage';

import NavbarUrl from '@/data/navbar';

import useWindowScroll from '@/lib/useWindowScroll';

const NavbarMobile = () => {
  const [showList, setShowList] = useState(false);
  // const Background = useSelector(selectNavbarBackgroundValue);
  const router = useRouter();
  const pathName = router.pathname;
  const scrollPos = useWindowScroll();

  const width = useWindowWidth();
  useEffect(() => {
    if (width > 750) {
      setShowList(false);
    }
  }, [width]);

  const navURL = NavbarUrl.map((v) => v.url);
  const basePath = pathName || '';
  const navbarURLIdx = navURL.indexOf(basePath);
  const navbarURLInfo = navbarURLIdx !== -1 ? NavbarUrl[navbarURLIdx] : null;

  let foregroundTop =
    scrollPos > 50 ||
    showList ||
    (pathName !== '/' && !!!navbarURLInfo?.transparentNavTop);

  return (
    <div className="relative">
      <div
        className={clsx(
          'flex flex-row justify-between items-center h-16 px-5 w-full duration-300 transition-all',
          foregroundTop ? 'bg-white shadow' : 'bg-transparent',
        )}
      >
        <NextLink
          href="/"
          passHref
          className="flex h-full w-[140px] items-center"
        >
          {foregroundTop ? (
            <img
              src="/logo/logo-kerjago-primary.png"
              alt="Logo Kerjago"
              width={115.92}
              height={32}
            />
          ) : (
            <img
              src="/logo/logo-kerjago-white.png"
              alt="Logo Kerjago"
              width={115.92}
              height={32}
            />
          )}
        </NextLink>
        <button
          type="button"
          className={clsx(
            'text-[32px] flex items-center justify-center',
            foregroundTop ? 'text-kerjago-primary' : 'text-white',
          )}
          onClick={() => setShowList(!showList)}
        >
          {showList ? <HiX /> : <HiMenu />}
        </button>
      </div>

      <div
        className={clsx(
          'bg-white shadow w-full absolute transition-all duration-300 px-5',
          showList ? `opacity-100` : `opacity-0 invisible`,
        )}
      >
        <div className="flex flex-col items-end w-full border-white">
          {NavbarUrl.map((data) => (
            <NextLink
              key={data.name}
              href={data.url}
              passHref
              onClick={() => setShowList(!showList)}
              className={clsx(
                'text-kerjago-primary py-5 cursor-pointer',
                pathName === data.url ? 'underline underline-offset-8' : '',
              )}
            >
              {data.name}
            </NextLink>
          ))}
        </div>
        {/* <div className="border-b border-kerjago-primary" /> */}
        {/* <div className="flex flex-col items-end w-full border-kerjago-primary">
          <NextLink href="/login" passHref>
            <a
              className={clsx(
                'text-kerjago-primary text-left py-5 cursor-pointer',
                pathName === '/login' ? 'underline underline-offset-8' : '',
              )}
            >
              Masuk
            </a>
          </NextLink>
          <NextLink href="/register" passHref>
            <a
              className={clsx(
                'text-kerjago-primary text-left py-5 cursor-pointer',
                pathName === '/register' ? 'underline underline-offset-8' : '',
              )}
            >
              Daftar
            </a>
          </NextLink>
        </div> */}
      </div>
    </div>
  );
};

export default NavbarMobile;
