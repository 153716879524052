import { ToastType } from '..';

interface ToastTitleProps {
  title: string | undefined;
  type: ToastType;
}

export default function ToastTitle({ title, type }: ToastTitleProps) {
  if (type === 'failed') {
    return <p className="font-semibold text-lg text-[#DB1F22]">{title}</p>;
  }

  if (type === 'succsess') {
    return <p className="font-semibold text-lg text-[#00855B]">{title}</p>;
  }

  if (type === 'warning') {
    return <p className="font-semibold text-lg text-[#DB6A00]">{title}</p>;
  }

  return <div />;
}
