import React from 'react';

import cn from '@/utils/cn';

const Template: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div className={cn('mx-auto w-[90%] max-w-screen-2xl', className)} {...props}>
    {children}
  </div>
);

export default Template;
