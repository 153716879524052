import { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type ToastType = 'succsess' | 'warning' | 'failed'

export interface ToastData {
  title: string | undefined
  description: string | undefined
  type: ToastType
}

export interface ToastDataIDTimeout extends ToastData {
  id: string
  // eslint-disable-next-line no-undef
  timeout: NodeJS.Timeout
}

export class ToastController {
  private readonly timeout: number;

  private dataSetter?: Dispatch<SetStateAction<ToastDataIDTimeout[]>>;

  public toastData?: ToastDataIDTimeout[];

  constructor(timeout: number) {
    this.timeout = timeout;
  }

  registerUseState(
    data: ToastDataIDTimeout[],
    setter: Dispatch<SetStateAction<ToastDataIDTimeout[]>>,
  ) {
    if (typeof window === 'undefined') return;
    this.toastData = data;
    this.dataSetter = setter;
  }

  private updateData(data: ToastDataIDTimeout[]) {
    if (typeof this.dataSetter !== 'undefined') {
      this.dataSetter(data);
    }
  }

  addToast({ type, title, description }: ToastData) {
    // Toast Controller is not registered
    if (typeof this.toastData === 'undefined') return;
    // Create toast
    const randomID = uuidv4();
    const newToast: ToastDataIDTimeout = {
      id: randomID,
      type,
      title,
      description,
      timeout: setTimeout(() => this.deleteToast(randomID), this.timeout),
    };
    // Push to the toast data
    const newData = [...this.toastData];
    newData.push(newToast);
    this.updateData(newData);
  }

  deleteToast(id: string) {
    if (typeof this.toastData === 'undefined') return;
    // Delete toast with an id from toastData
    let idxPop = -1;
    for (let i = 0; i < this.toastData.length; i += 1) {
      if (this.toastData[i].id === id) {
        idxPop = i;
        break;
      }
    }
    if (idxPop !== -1) {
      const newData = [...this.toastData];
      newData.splice(idxPop, 1);
      this.updateData(newData);
    }
  }

  resetTimeout(id: string) {
    if (typeof this.toastData === 'undefined') return;

    // Create new timeout with resetted timeout time
    // Delete toast with an id from toastData
    let idxInterest = -1;
    for (let i = 0; i < this.toastData.length; i += 1) {
      if (this.toastData[i].id === id) {
        idxInterest = i;
        break;
      }
    }
    if (idxInterest !== -1) {
      clearTimeout(this.toastData[idxInterest].timeout);
      this.toastData[idxInterest].timeout = setTimeout(() => {
        if (typeof this.toastData === 'undefined') return;
        this.deleteToast(this.toastData[idxInterest].id);
      }, this.timeout);
    }
  }
}
