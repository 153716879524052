import React from 'react';
import { ToastController } from '..';
import ToastRenderer from './ToastRenderer';

interface ToastProviderProps {
  context: React.Context<ToastController>;
  controller: ToastController;
  children?: React.ReactNode;
}

function ToastProvider({ context, controller, children }: ToastProviderProps) {
  return (
    <context.Provider value={controller}>
      {children}
      <ToastRenderer context={context} />
    </context.Provider>
  );
}

export default ToastProvider;
