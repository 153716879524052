import React from 'react';
import { ToastData } from '..';
import ToastLogo from './ToastLogo';
import ToastTitle from './ToastTitle';

interface ToastComponentProps {
  data: ToastData;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function ToastComponent({ data, onClick }: ToastComponentProps) {
  return (
    <button
      className="p-4 flex flex-row gap-4 items-center text-left max-w-xs rounded-lg bg-white hover:bg-slate-50 transition-all duration-100 active:scale-[98%] shadow-md shadow-slate-200 border-slate-50 border"
      onClick={onClick}
    >
      <div className="w-[30px] h-[30px]">
        <ToastLogo type={data.type} />
      </div>
      <div className="flex flex-col">
        <ToastTitle title={data.title} type={data.type} />
        <p className="text-sm">{data.description}</p>
      </div>
    </button>
  );
}

export default ToastComponent;
