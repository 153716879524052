import cn from 'clsx';
import Image from 'next/legacy/image';

import { BlurImageProps } from './types';

const BlurImage = (props: BlurImageProps) => {
  const { className } = props;
  // const [isLoading, setLoading] = useState(true);

  return (
    <Image {...props} className={cn('duration-700 ease-in-out', className)} />
  );
};

BlurImage.defaultProps = {
  className: '',
};

export default BlurImage;
