import { combineReducers, configureStore } from '@reduxjs/toolkit';

import navbarBackgroundSlice from './navbarBackgroundSlice';
import navbarUrlSlice from './navbarUrlSlice';

const GlobalStore = configureStore({
  reducer: combineReducers({
    url: navbarUrlSlice,
    background: navbarBackgroundSlice,
  }),
});

export type RootState = ReturnType<typeof GlobalStore.getState>;

export default GlobalStore;
