import React, { useState } from 'react';
import { ToastController, ToastDataIDTimeout } from '..';
import { useToast } from '../useToast';
import ToastComponent from './ToastComponent';

interface ToastRendererProps {
  context: React.Context<ToastController>;
}

function ToastRenderer({ context }: ToastRendererProps) {
  // Get controller
  const controller = useToast(context);

  // Create renderer state
  const [ListToastData, setListToastData] = useState<ToastDataIDTimeout[]>([]);

  // Reigster renderer state to the controller
  controller.registerUseState(ListToastData, setListToastData);

  return (
    <div className="fixed z-50 bottom-2 right-2 max-w-md">
      <div className="flex flex-col gap-2">
        {ListToastData.map((v) => (
          <ToastComponent
            key={v.id}
            data={{
              description: v.description,
              title: v.title,
              type: v.type,
            }}
            onClick={() => controller.deleteToast(v.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default ToastRenderer;
