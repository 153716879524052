import { NextSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { Provider } from 'react-redux';

import GlobalStore from '@/redux/globalStore';

import '@/styles/globals.css';

import { MetaHome } from '@/data/meta';

import PageContainer from '@/layouts/container';
import {
  GlobalToastContext,
  GlobalToastController,
} from '@/lib/toast/globalToast';
import ToastProvider from '@/lib/toast/view/ToastProvider';

const MyApp = ({ Component, pageProps }: AppProps) => (
  <div className="">
    <Script
      strategy="lazyOnload"
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_ANALYTICS_ID}`}
    />
    <Script
      strategy="lazyOnload"
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_ANALYTICS_ID_2}`}
    />
    <Script strategy="lazyOnload" id="gtag">
      {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-9M12MPPP79', {
              page_path: window.location.pathname,
              });
          `}
    </Script>
    <Script strategy="lazyOnload" id="gtag">
      {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-2M0JYKR92Y', {
              page_path: window.location.pathname,
              });
          `}
    </Script>
    {/* <NextSeo
      title={MetaHome.title}
      description={MetaHome.description}
      additionalMetaTags={[
        {
          name: 'keywords',
          content: MetaHome.keywords.join(','),
        },
      ]}
      openGraph={{
        type: 'website',
        url: MetaHome.url,
        description: MetaHome.description,
        images: [
          {
            url: '/thumb_hd.png',
            width: 1280,
            height: 720,
            alt: 'Kerjago Cover HD',
            type: 'image/png',
          },
          {
            url: '/thumb_square.png',
            width: 500,
            height: 500,
            alt: 'Kerjago Cover Square',
            type: 'image/png',
          },
        ],
      }}
    /> */}

    <Provider store={GlobalStore}>
      <ToastProvider
        key={'global_toast'}
        context={GlobalToastContext}
        controller={GlobalToastController}
      >
        <PageContainer>
          <Component {...pageProps} />
        </PageContainer>
      </ToastProvider>
    </Provider>
  </div>
);

export default MyApp;
