const DataFooter = [
  {
    title: 'Produk Kami',
    value: [
      {
        name: 'Mock Interview',
        url: '/mock-interview',
      },
      {
        name: 'CV Review',
        url: '/cv-review',
      },
    ],
  },
  {
    title: 'Blog & Artikel',
    value: [
      {
        name: "blog",
        url: "/blog"
      }
    ],
  },
];

export default DataFooter;
