import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from './globalStore';

const navbarUrlSlice = createSlice({
  name: 'url',
  initialState: {
    value: '',
  },
  reducers: {
    changeNavbarUrl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeNavbarUrl } = navbarUrlSlice.actions;

export const selectNavbarUrlValue = (state: RootState) => state.url.value;

export default navbarUrlSlice.reducer;
