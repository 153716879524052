import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from './globalStore';

const navbarBackgroundSlice = createSlice({
  name: 'background',
  initialState: {
    value: true,
  },
  reducers: {
    changeNavbarBackground: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeNavbarBackground } = navbarBackgroundSlice.actions;

export const selectNavbarBackgroundValue = (state: RootState) =>
  state.background.value;

export default navbarBackgroundSlice.reducer;
