type NavbarUrlType = {
  name: string;
  url: string;
  transparentNavTop?: boolean;
};

const NavbarUrl: NavbarUrlType[] = [
  {
    name: 'Blog',
    url: '/blog',
  },
  {
    name: 'Loker',
    url: '/loker',
    transparentNavTop: true,
  },
  {
    name: 'Mock Interview',
    url: '/mock-interview',
  },
  {
    name: 'CV Review',
    url: '/cv-review',
  },
  {
    name: 'Testimoni',
    url: '/testimony',
    transparentNavTop: true,
  },
];

export default NavbarUrl;
