// import NavbarWeb from './desktop';
import DesktopNavbar from './desktop';
import NavbarMobile from './mobile';

const Navbar = () => (
  <nav className="z-50 w-full top-0 fixed">
    <div className="hidden md:block">
      <DesktopNavbar />
    </div>
    <div className="md:hidden  w-full">
      <NavbarMobile />
    </div>
  </nav>
);

export default Navbar;
