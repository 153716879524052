import { HiXCircle, HiCheckCircle, HiExclamationCircle } from 'react-icons/hi';
import { ToastType } from '..';

interface ToastLogoProps {
  type: ToastType;
}

export default function ToastLogo({ type }: ToastLogoProps) {
  if (type === 'failed') {
    return <HiXCircle size={30} color="#DB1F22" />;
  }

  if (type === 'succsess') {
    return <HiCheckCircle size={30} color="#00855B" />;
  }

  if (type === 'warning') {
    return <HiExclamationCircle size={30} color="#DB6A00" />;
  }

  return <div />;
}
