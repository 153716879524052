import NextLink from 'next/link';

import BlurImage from '@/components/blurImage';

const Logo = () => (
  (<NextLink href="/" passHref className=" flex h-full w-[140px] items-center mx-2">

    <BlurImage
      priority
      src="/logo/logo-kerjago-white.png"
      alt="Logo Kerjago"
      width={115.92}
      height={32}
    />

  </NextLink>)
);

export default Logo;
