import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import BlurImage from '@/components/blurImage';

import NavbarUrl from '@/data/navbar';

import useWindowScroll from '@/lib/useWindowScroll';

const DesktopNavbar = () => {
  const router = useRouter();
  const pathName = router.pathname;
  const scrollPos = useWindowScroll();

  const navURL = NavbarUrl.map((v) => v.url);
  const basePath = pathName || '';
  const navbarURLIdx = navURL.indexOf(`${basePath}`);
  const navbarURLInfo = navbarURLIdx !== -1 ? NavbarUrl[navbarURLIdx] : null;

  const foregroundTop =
    scrollPos > 50 || (pathName !== '/' && !!!navbarURLInfo?.transparentNavTop);

  return (
    <div className={clsx(foregroundTop ? 'bg-white shadow' : 'bg-transparent')}>
      <div className="navbar h-16 layout">
        <div className="navbar-start">
          <NextLink
            href="/"
            passHref
            className="btn bg-transparent rounded-md border-none hover:bg-transparent -ml-6"
          >
            {foregroundTop ? (
              <img
                src="/logo/logo-kerjago-primary.png"
                alt="Logo Kerjago"
                width={115.92}
                height={32}
              />
            ) : (
              <img
                src="/logo/logo-kerjago-white.png"
                alt="Logo Kerjago"
                width={115.92}
                height={32}
              />
            )}
          </NextLink>
        </div>
        <div className="navbar-center">
          <ul className="flex flex-row p-0">
            {NavbarUrl.map((item) => (
              <li key={item.name}>
                <NextLink
                  key={item.name}
                  href={item.url}
                  passHref
                  className={clsx(
                    'btn btn-ghost rounded-md capitalize text-base font-normal mx-1 ',
                    foregroundTop ? 'text-kerjago-primary' : 'text-white',
                    pathName === item.url ? 'underline underline-offset-8' : '',
                  )}
                >
                  {item.name}
                </NextLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="navbar-end">
          {/* <ul className="menu menu-horizontal">
            <li>
              <NextLink href="/login" passHref>
                <a
                  className={clsx(
                    'btn btn-ghost rounded-lg capitalize text-base font-normal mx-1',
                    pathName === '/login' ? 'underline underline-offset-8' : '',
                    scrollPos > 50 || pathName !== '/'
                      ? 'text-kerjago-primary'
                      : 'text-white',
                  )}
                >
                  Masuk
                </a>
              </NextLink>
            </li>
            <li>
              <NextLink href="/register" passHref>
                <a
                  className={clsx(
                    'btn bg-kerjago-yellow hover:bg-kerjago-yellow/80 border-none rounded-lg capitalize text-base font-normal mx-1 text-kerjago-primary',
                    pathName === '/register'
                      ? 'underline underline-offset-8'
                      : '',
                  )}
                >
                  Daftar
                </a>
              </NextLink>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default DesktopNavbar;
