import Image from 'next/image';
import NextLink from 'next/link';

import DataFooter from '@/data/footer';

import Template from '@/layouts/template';

import Logo from '../logo';

const Footer = () => (
  <div className="mt-auto">
    <div className="mt-20 bg-[#12181F]">
      <Template>
        <div className="py-12">
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col md:w-1/2">
              <div className="-ml-3">
                <Logo />
              </div>
              <div className="mt-6">
                <p className="text-white mb-10">
                  Kerjago adalah sebuah platform yang dikembangkan untuk
                  membantu pengguna dalam mempersiapkan diri terjun ke dunia
                  kerja.
                </p>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2  gap-2 md:w-1/2 md:mx-4 ">
              {DataFooter.map((data) => (
                <div key={data.title} className=" text-white  ">
                  <p className="font-semibold">{data.title}</p>
                  {data.value.map((v) => (
                    <div className="mt-[8px]" key={v.name}>
                      <NextLink href={v.url} passHref className="text-white">
                        {v.name}
                      </NextLink>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-4 mt-10 md:mt-0 items-center">
            <NextLink
              href="mailto:mail.kerjago@gmail.com"
              passHref
              className="flex items-center">

              <Image
                width={24}
                height={24}
                src="/footer/Gmail.svg"
                alt="Gmail"
              />

            </NextLink>
            <NextLink href="https://wa.me/6285888163165" passHref className="flex items-center">

              <Image
                width={24}
                height={24}
                src="/footer/Whatsapp.svg"
                alt="Whatsapp"
              />

            </NextLink>
            <NextLink
              href="https://instagram.com/kerjago"
              passHref
              className="flex items-center">

              <Image
                width={24}
                height={24}
                src="/footer/Instagram.svg"
                alt="Instagram"
              />

            </NextLink>
            <NextLink href="https://titkok.com/@kerjago" passHref className="flex items-center">

              <Image
                width={24}
                height={24}
                src="/footer/Tiktok.svg"
                alt="Instagram"
              />

            </NextLink>
            <NextLink href="https://twitter.com/kerjago" passHref className="flex items-center">

              <Image
                width={24}
                height={24}
                src="/footer/Twitter.svg"
                alt="Instagram"
              />

            </NextLink>
            <NextLink
              href="https://linkedin.com/company/kerjago"
              passHref
              className="flex items-center">

              <Image
                width={24}
                height={24}
                src="/footer/Linkedin.svg"
                alt="Instagram"
              />

            </NextLink>
          </div>
        </div>
      </Template>
    </div>
    <div className="bg-kerjago-primary py-3 w-full">
      <p className="text-center text-white">
        © 2023 Kerjago All rights reserved.
      </p>
    </div>
  </div>
);

export default Footer;
